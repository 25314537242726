import React from 'react';

function Home() {
  return (
    <div id="home">
      <h3>Welcome to our page!</h3>
      <p>
        Calculators have not only greatly enhanced our ability to perform the
        regular computations that are involved in everyday life, but provided
        humans with the ability to understand mathematics on a greater scale than
        ever imagined.
      </p>
      <p>
        This technology allows students solve complicated problems quickly and in
        an efficient manner. Additionally, it can reduce the problem to simpler
        tasks and allows the student to devote more time in understanding the
        problem. Secondly, they are saved from monotonous calculations and the
        same boring mundane procedure.
      </p>
    </div>
  );
}

export default Home;
